import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import DatePicker from 'react-datepicker';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';

import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';
import {getInitialDate} from 'helpers/datepickerHelper';

import {DATEPICKER_FORMAT} from 'config/index';
import {theme, ERROR_TEXT_COLOR} from 'config/theme';

const styles = () =>
    createStyles({
        datepicker: {
            fontSize: 16,
            backgroundColor: '#ffffff',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottomColor: '#c4c4c4',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            padding: 0,
            width: '100%',
            height: 42,
            '&:hover': {
                borderColor: '#222222',
                borderWidth: 1.5,
            },
            '&:focus': {
                outline: 'none',
                borderColor: '#222222',
                borderWidth: 2,
            },
        },
    });

const FormDatepicker: FC<FormFieldPropsType & WithStyles<typeof styles>> = ({
    classes,
    formId,
    field,
    pageId,
    sectionId,
    clientInformation,
    handleChangeInformation,
    onPopupFieldChange,
    isPopup,
    popupContent,
    disabled,
    previewMode,
    onSaveClicked,
}) => {
    const [t] = useTranslation();

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const {id, label, tooltip} = field;

    const [date, setDate] = useState<Date>(new Date());

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleChange = (date: Date): void => {
        setDate(date);
        setErrorField && setErrorField(null);
        if (handleChangeInformation) {
            handleChangeInformation(pageId, sectionId, id, format(date, DATEPICKER_FORMAT));
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    const handlePopupFieldChange = (date: Date): void => {
        setDate(date);
        setErrorField && setErrorField(null);
        if (onPopupFieldChange) {
            onPopupFieldChange(id, format(date, DATEPICKER_FORMAT));
            setIsInitialRender(false);
            setIsUpdated(previous => !previous);
        }
    };

    const keywordLabel = getFieldKeyword(formId, id, 'label');

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    const isError = id === errorField;

    useEffect(() => {
        if (clientInformation) {
            const initialData = getInitialDate(
                getInitialFieldValue(pageId, sectionId, id, clientInformation, isPopup, popupContent),
            );
            setDate(initialData);
        }
    }, [clientInformation]);

    return (
        <FormFieldWrapper
            data-id={`input#from-datapicker-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            formId={formId}
            field={field}
            isPopup={isPopup}
            previewMode={previewMode}
        >
            <Typography
                sx={{
                    color: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                    fontSize: 12,
                }}
            >
                {`${previewMode ? label : getFormTranslatedLabel(translations, keywordLabel, label)} ${
                    field.isRequired ? '*' : ''
                }`}
            </Typography>
            <DatePicker
                disabled={disabled}
                className={classes.datepicker}
                selected={date}
                onChange={isPopup ? handlePopupFieldChange : handleChange}
                dateFormat={DATEPICKER_FORMAT}
                placeholderText={tooltip}
                showYearDropdown
            />
            {isError && (
                <Typography sx={{fontSize: 12, color: ERROR_TEXT_COLOR}}>
                    {t('messages.error.errorIsRequired')}
                </Typography>
            )}
        </FormFieldWrapper>
    );
};

export default withStyles(styles)(FormDatepicker);
