import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import Typography from '@mui/material/Typography';
import {createStyles, WithStyles, withStyles} from '@material-ui/core';
import {Box} from '@mui/material';

import {RootReducer} from 'appRedux/reducers';

import {ClientFormContext} from 'contexts/clientForm/context';

import FormFieldWrapper from 'pages/client/form/wrappers/FormFieldWrapper';
import {FormFieldPropsType} from 'pages/client/form/partials/types';
import {getInitialFieldValue} from 'pages/client/form/helper';
import {DATE_SEPARATOR} from 'pages/admin/updateForm/partials/FormStructure/helper';

import {getFieldKeyword, getFormTranslatedLabel} from 'helpers/translationsHelper';

import {DATE_FORMAT, DATEPICKER_FORMAT} from 'config/index';
import {theme, ERROR_TEXT_COLOR} from 'config/theme';

const styles = () =>
    createStyles({
        datepicker: {
            fontSize: 16,
            backgroundColor: '#ffffff',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottomColor: '#c4c4c4',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
            padding: 0,
            height: 42,
            width: '100%',
            '&:hover': {
                borderColor: '#222222',
                borderWidth: 1.5,
            },
            '&:focus': {
                outline: 'none',
                borderColor: '#222222',
                borderWidth: 2,
            },
        },
    });

const getInitialDate = (dateString: string | null) => {
    if (!dateString) return [new Date(), new Date()];
    const dateStringArray = dateString.split(DATE_SEPARATOR).slice(0, 2);
    return dateStringArray.map(string => {
        const dateInFormat = moment(string, DATE_FORMAT).format('YYYY-MM-DD');
        const dateObject = new Date(dateInFormat);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth();
        const date = dateObject.getDate();
        return new Date(year, month, date);
    });
};

const FormDateRange: FC<FormFieldPropsType & WithStyles<typeof styles>> = ({
    classes,
    formId,
    field,
    pageId,
    sectionId,
    clientInformation,
    handleChangeInformation,
    onPopupFieldChange,
    isPopup,
    popupContent,
    disabled,
    previewMode,
    onSaveClicked,
}) => {
    const [t] = useTranslation();

    const ref = useRef<HTMLDivElement | undefined>();

    const {errorField, setErrorField} = useContext(ClientFormContext);

    const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    const {id, label, tooltip} = field;

    const [dateRange, setDateRange] = useState<Date[]>([new Date(), new Date()]);

    const {
        admin: {
            formInfo: {translations},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const handleChange = (value: Date[]): void => {
        if (Array.isArray(value) && value[0] && value[1]) {
            setErrorField && setErrorField(null);
            if (handleChangeInformation) {
                handleChangeInformation(
                    pageId,
                    sectionId,
                    id,
                    value.map(date => format(date, DATEPICKER_FORMAT)).join(DATE_SEPARATOR),
                );
                setIsUpdated(previous => !previous);
            }
        }
    };

    const handlePopupFieldChange = (value: Date[]): void => {
        if (Array.isArray(value) && value[0] && value[1]) {
            setErrorField && setErrorField(null);
            if (onPopupFieldChange) {
                onPopupFieldChange(id, `${value.map(date => format(date, DATEPICKER_FORMAT)).join(DATE_SEPARATOR)}`);
                setIsUpdated(previous => !previous);
            }
        }
    };

    useEffect(() => {
        const handleDateChange = isPopup ? handlePopupFieldChange : handleChange;
        handleDateChange(dateRange);
    }, [dateRange, isPopup]);

    const onChange = (index: number) => date => {
        if (date) {
            setDateRange(prev => {
                const newValue = [...prev];
                newValue[index] = date;
                return newValue;
            });
            setIsInitialRender(false);
        }
    };

    const keywordLabel = getFieldKeyword(formId, id, 'label');

    useEffect(() => {
        if (id === errorField && ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorField]);

    useEffect(() => {
        if (!isInitialRender) {
            onSaveClicked();
        }
    }, [isUpdated]);

    useEffect(() => {
        if (clientInformation) {
            const initialDataRange = getInitialDate(
                getInitialFieldValue(pageId, sectionId, id, clientInformation, isPopup, popupContent),
            );
            setDateRange(initialDataRange);
        }
    }, [clientInformation]);

    const isError = id === errorField;

    return (
        <FormFieldWrapper
            data-id={`input#from-datapicker-${formId}-${pageId}-${sectionId}-${field.id}`}
            wrapperRef={ref}
            formId={formId}
            field={field}
            isPopup={isPopup}
            previewMode={previewMode}
        >
            <Typography
                sx={{
                    color: isError ? ERROR_TEXT_COLOR : theme.palette.info.main,
                    fontSize: 12,
                }}
            >
                {`${previewMode ? label : getFormTranslatedLabel(translations, keywordLabel, label)} ${
                    field.isRequired ? '*' : ''
                }`}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    '& .react-datepicker-wrapper': {
                        flex: 1,
                    },
                }}
            >
                <DatePicker
                    disabled={disabled}
                    className={classes.datepicker}
                    selected={dateRange[0]}
                    onChange={onChange(0)}
                    dateFormat={DATEPICKER_FORMAT}
                    placeholderText={tooltip}
                    showYearDropdown
                />
                <Typography
                    sx={{
                        fontSize: 16,
                        mb: '2px',
                        mx: 2,
                    }}
                >
                    &ndash;
                </Typography>
                <DatePicker
                    disabled={disabled}
                    className={classes.datepicker}
                    selected={dateRange[1]}
                    onChange={onChange(1)}
                    dateFormat={DATEPICKER_FORMAT}
                    placeholderText={tooltip}
                    showYearDropdown
                />
            </Box>
            {isError && (
                <Typography sx={{fontSize: 12, color: ERROR_TEXT_COLOR}}>
                    {t('messages.error.errorIsRequired')}
                </Typography>
            )}
        </FormFieldWrapper>
    );
};

export default withStyles(styles)(FormDateRange);
