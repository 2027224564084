import React, {FC, KeyboardEvent, RefObject, useCallback, useContext, useRef, useState} from 'react';
import {Field, Form, Formik, FormikProps} from 'formik';
import get from 'lodash/get';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';

import HerupuSpinner from 'assets/herupuSpinner';

import {ChatUploadDocumentRequestTypes} from 'appRedux/actions/requestChat/types';
import {GET_REQUESTER_CASE, GET_REQUESTER_CASE_CLIENT} from 'appRedux/actions/requestCase';
import {UPLOAD_CHAT_DOCUMENT} from 'appRedux/actions/requestChat';
import {RootReducer} from 'appRedux/reducers';

import {AdminContext} from 'contexts/admin/context';
import {AlertContext} from 'contexts/alert/context';
import {CaseKeyContext} from 'contexts/caseKey/context';
import {RouteContext} from 'contexts/route/context';

import {chatMessageValidation} from 'components/Forms/RequesterCaseChatForm/validation';
import FormBuilderTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FileDropArea from 'components/ClientScreenComponents/FileDropArea';

import {base64ToFile, createThumbnail} from 'pages/client/form/fieldTypes/helper';

import {encryptStringWithKey, encryptBase64FileWithKey, extractBase64FromFileContent} from 'helpers/cryptoApiHelper';

import {theme} from 'config/theme';
import {PARAMETER_MESSAGE_ID} from 'config/index';

interface RequesterCaseChatFormType {
    caseId?: string;
    resetSelectedSection: () => void;
    showFileInfectedMessage: (value: number | null) => void;
    setErrors: (value: IErrors) => void;
    wrapperRef?: RefObject<HTMLDivElement | undefined>;
}

const RequesterCaseChatForm: FC<RequesterCaseChatFormType> = ({
    caseId,
    resetSelectedSection,
    showFileInfectedMessage,
    setErrors,
    wrapperRef,
}) => {
    const [t] = useTranslation();
    const {requestCase} = useParams();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const {showAlert} = useContext(AlertContext);
    const {unwrappedCaseKey} = useContext(CaseKeyContext);
    const {questionSectionId} = useContext(AdminContext);
    const {isClientMode} = useContext(RouteContext);

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const textInputRef = useRef<HTMLInputElement | null>(null);

    const [uploadingFileName, setUploadingFileName] = useState<string>();

    const {
        requestChat: {isFileLoading, newestMessageId},
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const validationSchema = () => chatMessageValidation(t);

    const sendChatMessage = useCallback(
        data => dispatch({type: UPLOAD_CHAT_DOCUMENT.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseInfoForClient = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_CLIENT.REQUEST, payload: data}),
        [dispatch],
    );

    const onLabelClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const id = Number(caseId ? caseId : requestCase);

    return (
        <Formik
            initialValues={{
                newestMessageId,
                caseId: id,
                file: '',
                thumb: null,
                section: questionSectionId,
                text: '',
            }}
            onSubmit={async (values, {resetForm}) => {
                if (isFileLoading || !newestMessageId) return;

                const text = get(values, ['text'], '');
                if (!text.length) return;

                const fileName = get(values, ['file', 'name'], '');
                setUploadingFileName(fileName);

                searchParams.delete(PARAMETER_MESSAGE_ID);
                setSearchParams(searchParams);

                resetForm();
                resetSelectedSection();

                fileInputRef.current?.focus();

                const commonPayload = {
                    ...values,
                    newestMessageId,
                    fileName,
                    setErrors,
                    id: caseId ? caseId : requestCase,
                    showAlert,
                    callbackInfectedFileError: showFileInfectedMessage,
                    callback: () => {
                        setUploadingFileName('');
                    },
                };

                if (isEncryptInfo && unwrappedCaseKey && values.file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(values.file as Blob);
                    reader.addEventListener('load', async e => {
                        const fileContent = get(e, ['currentTarget', 'result'], null);
                        const {base64, prefix} = extractBase64FromFileContent(fileContent);

                        if (base64) {
                            const encrypted = await encryptBase64FileWithKey(base64, unwrappedCaseKey);
                            const encryptedFile = new Blob([encrypted], {
                                type: 'application/octet-stream',
                            });

                            let encryptedThumb: Blob | null = null;

                            if (values.thumb) {
                                const {base64: thumbBase64} = extractBase64FromFileContent(values.thumb);
                                const encryptedArr = thumbBase64
                                    ? await encryptBase64FileWithKey(thumbBase64, unwrappedCaseKey)
                                    : '';
                                encryptedThumb = new Blob([encryptedArr], {
                                    type: 'application/octet-stream',
                                });
                            }

                            sendChatMessage({
                                ...commonPayload,
                                file: encryptedFile,
                                encryptPrefix: prefix,
                                thumb: encryptedThumb,
                                text: await encryptStringWithKey(values.text, unwrappedCaseKey),
                                callback: () => {
                                    if (isClientMode) {
                                        getRequesterCaseInfoForClient({id: caseId ? caseId : requestCase});
                                    } else {
                                        getRequesterCaseInfo({id: caseId ? caseId : requestCase});
                                    }
                                },
                            });
                        }
                    });
                } else if (isEncryptInfo && unwrappedCaseKey) {
                    sendChatMessage({
                        ...commonPayload,
                        text: await encryptStringWithKey(values.text, unwrappedCaseKey),
                    });
                } else {
                    sendChatMessage({
                        ...commonPayload,
                        thumb: values.thumb && base64ToFile(values.thumb),
                        callback: () => {
                            if (isClientMode) {
                                getRequesterCaseInfoForClient({id: caseId ? caseId : requestCase});
                            } else {
                                getRequesterCaseInfo({id: caseId ? caseId : requestCase});
                            }
                        },
                    });
                }
            }}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {(formik: FormikProps<ChatUploadDocumentRequestTypes>) => {
                const filename = get(formik, ['values', 'file', 'name'], null);
                const text = get(formik, ['values', 'text'], '');

                return (
                    <Form
                        style={{width: '100%'}}
                        onKeyDown={(keyEvent: KeyboardEvent<HTMLFormElement>) => {
                            if (keyEvent.shiftKey && (keyEvent.charCode || keyEvent.keyCode) === 13) {
                                keyEvent.preventDefault();
                                formik.submitForm();
                            }
                        }}
                    >
                        {wrapperRef && (
                            <FileDropArea
                                wrapperRef={wrapperRef}
                                onDropFile={(newFile: File) => {
                                    formik.setFieldValue('file', newFile);
                                    createThumbnail(newFile).then(thumb => {
                                        thumb && formik.setFieldValue('thumb', thumb);
                                    });
                                }}
                                fullPage={isClientMode}
                            />
                        )}

                        {isFileLoading && (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: 20,
                                    mb: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <HerupuSpinner size={20} />
                                <Typography
                                    sx={{
                                        ml: 1,
                                        color: theme.palette.info.main,
                                        fontWeight: 600,
                                        fontSize: 12,
                                    }}
                                >
                                    {t(uploadingFileName ? 'orguser.chat.fileUploading' : 'common.pleaseWait')}
                                </Typography>
                            </Box>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{mr: 1}}>
                                {filename ? (
                                    <IconButton
                                        data-id={`button#chat-file-close`}
                                        onClick={() => {
                                            formik.setFieldValue('file', '');
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton data-id={`button#chat-file-add`} onClick={onLabelClick}>
                                        <AddIcon />
                                    </IconButton>
                                )}
                                <TextField
                                    inputRef={fileInputRef}
                                    sx={{display: 'none'}}
                                    fullWidth
                                    variant="standard"
                                    name="file"
                                    type="file"
                                    onChange={value => {
                                        const newFile = get(value, ['currentTarget', 'files', '0'], null);
                                        formik.setFieldValue('file', newFile);
                                        createThumbnail(newFile).then(thumb => {
                                            thumb && formik.setFieldValue('thumb', thumb);
                                        });
                                    }}
                                />
                            </Box>
                            <Field
                                data-id={`input#chat-text`}
                                name="text"
                                component={FormBuilderTextInput}
                                fullWidth
                                required
                                margin="none"
                                multiline
                                ref={textInputRef}
                                sx={{
                                    '& div': {
                                        backgroundColor: theme.palette.background.default,
                                        py: 1,
                                        px: 2,
                                        borderRadius: 5,
                                    },
                                }}
                                variant="outlined"
                            />
                            <IconButton
                                data-id={`button#chat-submit`}
                                type="submit"
                                sx={{
                                    ml: 2,
                                    backgroundColor:
                                        isFileLoading || !text.length
                                            ? theme.palette.info.dark
                                            : theme.palette.info.main,
                                    '&:hover': {
                                        backgroundColor:
                                            isFileLoading || !text.length
                                                ? theme.palette.info.dark
                                                : theme.palette.info.main,
                                    },
                                }}
                            >
                                <SendIcon
                                    fontSize="small"
                                    sx={{
                                        color: theme.palette.background.default,
                                        '&:hover': {color: theme.palette.background.default},
                                    }}
                                />
                            </IconButton>
                        </Box>
                        {filename && (
                            <Box
                                sx={{
                                    mt: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    style={{
                                        color: theme.palette.info.main,
                                        fontWeight: 600,
                                        fontSize: 12,
                                    }}
                                >{`${t('orguser.chat.attachedDocument')}: ${filename}`}</Typography>
                            </Box>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default RequesterCaseChatForm;
