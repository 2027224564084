import React, {FC, ReactNode, useCallback, useContext, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import HerupuSpinner from 'assets/herupuSpinner';

import {RootReducer} from 'appRedux/reducers';
import {MessageTypes} from 'appRedux/actions/requestChat/types';
import {REFRESH_CHAT_MESSAGES} from 'appRedux/actions/requestChat';
import {SET_CURRENT_BOARD} from 'appRedux/actions/workflow';

import {MediaContext} from 'contexts/media/context';
import {WebsocketContext} from 'contexts/websocket/context';

import ChatMessageEncrypt from 'components/RequesterChat/ChatMessageEncrypt';
import ChatMessage from 'components/RequesterChat/ChatMessage';
import {getDateLabel} from 'components/RequesterChat/helper';
import useLoadMessagesList from 'components/RequesterChat/useLoadMessagesList';
import ScrollChatToBottom from 'components/RequesterChat/partials/ScrollChatToBottom';

import {PARAMETER_MESSAGE_ID} from 'config/index';
import {theme} from 'config/theme';

interface ChatMessagesListType {
    isRequesterChat?: boolean;
}

const ChatMessagesList: FC<ChatMessagesListType> = ({isRequesterChat}) => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const {requestCase} = useParams();
    const [searchParams] = useSearchParams();

    const currentMessageId = searchParams.get(PARAMETER_MESSAGE_ID);

    const {isMobile} = useContext(MediaContext);
    const {isShowScrollBottomButton, setIsShowScrollBottomButton, notReadMessagesCounter, setNotReadMessagesCounter} =
        useContext(WebsocketContext);

    const messagesEndRef = useRef<HTMLDivElement>();
    const isScrolledRef = useRef<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const setCurrentBoard = useCallback(data => dispatch({type: SET_CURRENT_BOARD, payload: data}), [dispatch]);

    const refreshChatMessages = useCallback(() => dispatch({type: REFRESH_CHAT_MESSAGES.REQUEST}), [dispatch]);

    useEffect(() => {
        setCurrentBoard(undefined);
    }, []);

    const {
        profile,
        requestCase: {
            currentCase: {isEncryptInfo},
        },
        requestChat: {isLoading, oldestMessageId, allMessagesLoaded},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;
    const userId = get(profile, ['profile', 'id'], null);

    const {messages, isMessagesLoading} = useLoadMessagesList({wrapperRef, isRequesterChat});

    useEffect(() => {
        if (isScrolledRef.current && messagesEndRef && messagesEndRef.current) {
            if (currentMessageId) return;
            messagesEndRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            return;
        }

        const scrollDelay = setTimeout(() => {
            if (!currentMessageId && messagesEndRef && messagesEndRef.current) {
                scrollToBottom();
            }
        }, 750);

        return () => clearTimeout(scrollDelay);
    }, [requestCase, messages.length]);

    const prevHeight = useRef<number>(0);

    useEffect(() => {
        const scrollHeight = wrapperRef.current?.scrollHeight;

        if (!scrollHeight) {
            prevHeight.current = 0;
            return;
        }

        if (scrollContainerRef?.current && prevHeight.current && scrollHeight > prevHeight.current) {
            scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollTop + scrollHeight - prevHeight.current;
        }

        prevHeight.current = scrollHeight;
    }, [oldestMessageId, messages.length]);

    useEffect(() => {
        setIsShowScrollBottomButton(false);
        setNotReadMessagesCounter(0);
    }, []);

    useEffect(() => {
        return () => {
            refreshChatMessages();
        };
    }, []);

    const scrollToBottom = () => {
        if (!currentMessageId && messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({block: 'start', behavior: 'smooth'});
            isScrolledRef.current = true;
            setIsShowScrollBottomButton(false);
            setNotReadMessagesCounter(0);
        }
    };

    let senderId = 0;
    let isSystem = false;
    let firstUnreadMessageId: string | null = null;

    const showScrollToBottomButton = isShowScrollBottomButton && notReadMessagesCounter > 0;

    return (
        <Box
            ref={scrollContainerRef}
            sx={{
                overflowY: 'scroll',
                display: 'flex',
                height: `calc(100vh - ${isRequesterChat ? (isMobile ? '128px' : '116px') : '248px'})`,
                flexDirection: 'column-reverse',
                justifyContent: 'flex-start',
                width: '100%',
            }}
        >
            <Grid container component="div" ref={wrapperRef}>
                {isRequesterChat && !isMobile && <Grid item sm={2}></Grid>}
                <Grid component="div" item sm={isRequesterChat && !isMobile ? 8 : 12}>
                    {isMessagesLoading ? (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: 'calc(100vh - 300px)',
                            }}
                        >
                            <HerupuSpinner size={100} />
                        </Box>
                    ) : (
                        <>
                            {messages.length === 0 && (
                                <Typography
                                    align="center"
                                    sx={{
                                        m: 2,
                                        fontWeight: 600,
                                        color: theme.palette.primary.contrastText,
                                        fontSize: isMobile ? 11 : 14,
                                    }}
                                >
                                    {t('orguser.chat.noMessagesYet')}
                                </Typography>
                            )}

                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: 50,
                                }}
                            >
                                {isLoading && !allMessagesLoaded && <HerupuSpinner size={35} />}
                            </Box>
                            <Box>
                                {messages.map((message: MessageTypes, messageIndex: number) => {
                                    const previousMessageSenderSame =
                                        senderId === message.senderId && isSystem === message.isSystemMessage;
                                    senderId = message.senderId;
                                    isSystem = message.isSystemMessage;

                                    let chatMessage: ReactNode;

                                    if (isEncryptInfo) {
                                        chatMessage = (
                                            <ChatMessageEncrypt
                                                key={`chat-encrypt-${requestCase}-message-${message.uuid}`}
                                                item={message}
                                                userId={userId}
                                                previousMessageSenderSame={previousMessageSenderSame}
                                            />
                                        );
                                    } else {
                                        chatMessage = (
                                            <ChatMessage
                                                key={`chat-${requestCase}-message-${message.uuid}`}
                                                item={message}
                                                caseId={Number(requestCase)}
                                                userId={userId}
                                                previousMessageSenderSame={previousMessageSenderSame}
                                                text={message.text}
                                            />
                                        );
                                    }

                                    if (
                                        messageIndex === 0 ||
                                        message.createdDate !== messages[messageIndex - 1].createdDate
                                    )
                                        chatMessage = (
                                            <>
                                                <Typography
                                                    align="center"
                                                    sx={{
                                                        m: 2,
                                                        fontWeight: 600,
                                                        color: theme.palette.primary.contrastText,
                                                        fontSize: isMobile ? 11 : 14,
                                                    }}
                                                >
                                                    {getDateLabel(t, message.createdDate)}
                                                </Typography>
                                                {chatMessage}
                                            </>
                                        );

                                    if (
                                        senderId !== userId &&
                                        !firstUnreadMessageId &&
                                        (!message.readAt ||
                                            new Date().getTime() - new Date(message.readAt).getTime() < 5000)
                                    ) {
                                        firstUnreadMessageId = message.uuid;
                                        chatMessage = (
                                            <>
                                                <Box ref={messagesEndRef} sx={{height: 1}} />
                                                {chatMessage}
                                            </>
                                        );
                                    }

                                    return chatMessage;
                                })}
                                {showScrollToBottomButton && (
                                    <ScrollChatToBottom
                                        notReadMessages={notReadMessagesCounter}
                                        onClickAction={scrollToBottom}
                                    />
                                )}
                            </Box>
                        </>
                    )}
                </Grid>
                {isRequesterChat && !isMobile && <Grid item sm={2}></Grid>}
            </Grid>
        </Box>
    );
};

export default ChatMessagesList;
