import {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import get from 'lodash/get';

import {GET_CHAT_MESSAGES, GET_PREV_CHAT_MESSAGES} from 'appRedux/actions/requestChat';
import {ChatMessageTypes} from 'appRedux/actions/requestChat/types';
import {GET_REQUESTER_CASE, GET_REQUESTER_CASE_CLIENT} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

interface UseLoadMessagesListType {
    wrapperRef: MutableRefObject<HTMLDivElement | null>;
    isRequesterChat?: boolean;
}

const useLoadMessagesList = ({wrapperRef, isRequesterChat}: UseLoadMessagesListType) => {
    const dispatch = useDispatch();
    const {requestCase} = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const getChatMessages = useCallback(data => dispatch({type: GET_CHAT_MESSAGES.REQUEST, payload: data}), [dispatch]);

    const getRequesterCaseInfo = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE.REQUEST, payload: data}),
        [dispatch],
    );

    const getRequesterCaseInfoForClient = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_CLIENT.REQUEST, payload: data}),
        [dispatch],
    );

    const getPrevChatMessages = useCallback(
        data => dispatch({type: GET_PREV_CHAT_MESSAGES.REQUEST, payload: data}),
        [dispatch],
    );

    const {
        requestChat,
        requestCase: {
            currentCase: {id: caseId},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const messages: ChatMessageTypes = useMemo(() => get(requestChat, 'messages', []), [requestChat]);

    const oldestMessageId: string = get(requestChat, 'oldestMessageId', '');
    const loadingRef = useRef<boolean>();

    const lastScrollTop = useRef<number>();

    useEffect(() => {
        if (requestChat.allMessagesLoaded || !oldestMessageId) return;
        loadingRef.current = false;

        const scrollHandler = (e: any) => {
            if (loadingRef.current) {
                e.preventDefault();
                return;
            }

            const bondRect = wrapperRef.current?.getBoundingClientRect();

            const loadOnScrollTop =
                lastScrollTop.current && bondRect && bondRect.y > lastScrollTop.current && bondRect.y > 0;

            const loadOnAnyScroll = lastScrollTop.current && bondRect && bondRect.y > 100;

            if (loadOnScrollTop || loadOnAnyScroll) {
                loadingRef.current = true;

                getPrevChatMessages({
                    id: requestCase || caseId,
                    lastMessageUuid: oldestMessageId,
                });
            }

            lastScrollTop.current = bondRect ? bondRect.y : 0;
        };

        window.addEventListener('scroll', scrollHandler, true);

        return () => {
            window.removeEventListener('scroll', scrollHandler, true);
        };
    }, [requestChat.allMessagesLoaded, oldestMessageId, requestCase, caseId]);

    useEffect(() => {
        if (!requestCase && !caseId) return;
        setIsLoading(true);
        console.log(' setIsLoading(true);');
    }, [requestCase]);

    useEffect(() => {
        setIsLoading(false);
        console.log(' setIsLoading(false);');
    }, [messages]);

    useEffect(() => {
        if (!requestCase && !caseId) return;

        getChatMessages({
            id: requestCase || caseId,
        });

        if (isRequesterChat) {
            getRequesterCaseInfoForClient({
                id: requestCase || caseId,
            });
        } else {
            getRequesterCaseInfo({
                id: requestCase || caseId,
            });
        }
    }, [requestCase, caseId]);

    return {messages, isMessagesLoading: isLoading};
};

export default useLoadMessagesList;
