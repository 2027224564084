import React, {FC, useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {GET_REQUESTER_CASE_CLIENT} from 'appRedux/actions/requestCase';
import {RootReducer} from 'appRedux/reducers';

import CaseKeyContextWrapper from 'contexts/caseKey';
import ClientFormContextWrapper from 'contexts/clientForm';

import ClientScreenWrapper from 'components/ClientScreenComponents/ClientScreenWrapper/ClientScreenWrapper';

import ClientForm from 'pages/client/form/ClientForm';

const RequesterCasePage: FC = () => {
    const {requestCase} = useParams();
    const dispatch = useDispatch();

    const {
        requestCase: {
            currentCase: {isEncryptInfo},
        },
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const getRequestCaseInformation = useCallback(
        data => dispatch({type: GET_REQUESTER_CASE_CLIENT.REQUEST, payload: data}),
        [dispatch],
    );

    useEffect(() => {
        getRequestCaseInformation({id: requestCase});
    }, []);

    return (
        <ClientFormContextWrapper>
            <ClientScreenWrapper>
                {isEncryptInfo ? (
                    <CaseKeyContextWrapper caseId={Number(requestCase)}>
                        <ClientForm />
                    </CaseKeyContextWrapper>
                ) : (
                    <ClientForm />
                )}
            </ClientScreenWrapper>
        </ClientFormContextWrapper>
    );
};

export default RequesterCasePage;
